<template>
    <div class="igo_top">
        <img class="top_icon_menu" src = "../../assets/images/mobile/icon_menu.png" @click="drawer = true"/>
        <img class="top_binance" src="../../assets/images/pc/binance-coin.png" v-if="isChainType === 2"/>
        <img class="top_binance_eth" src="../../assets/images/pc/eth_logo.png" v-if="isChainType === 3"/>
        <img class="top_binance" src="../../assets/images/pc/avax_logo.png" v-if="isChainType === 4"/>
        <img class="top_binance" src="../../assets/images/pc/polygon_logo.png" v-if="isChainType === 5"/>
        <div class="lianjie_anniu" @click="showConnectWallet()">
            {{$store.state.accounts.length === 0 ? anniuText : cutAccount($store.state.accounts[0], 4)}}
        </div>

        <el-drawer
                title=""
                :visible.sync="drawer"
                :direction="direction"
                :before-close="handleClose">
            <div class="top_mobile_menu">
                <MobileMenu/>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import MobileMenu from "./MobileMenu";
    import {cutAccount} from '../../tools/index'

    export default {
        name: "Top",
        components: {MobileMenu},
        data() {
            return {
                isChainType: 2,
                anniuText: "Connect",
                drawer: false,
                direction: 'ltr',
            };
        },
        watch: {
        },
        created() {
        },
        mounted() {
            this.getIsBsc()
        },
        beforeDestroy() {
        },
        methods: {
            showConnectWallet() {
                this.$store.commit('setState', {
                    connectWalletShow: true
                })
            },
            getIsBsc() {
                if (sessionStorage.getItem('urlIndex')) {
                    this.isChainType = parseInt(sessionStorage.getItem('urlIndex'))
                } else {
                    this.isChainType = 2
                }
            },
            cutAccount(account, num) {
                return cutAccount(account, num);
            },
            handleClose(done) {
                done();
            }
        }
    }
</script>

<style lang="less">
    .el-drawer {
        @media screen and (max-width: 768px) {
            width: 70% !important;
            background-color: #171621 !important;
        }
    }
    .el-drawer__close-btn {
        @media screen and (max-width: 768px) {
            color: white !important;
            font-size: 45px !important;
        }
    }
    .el-drawer__header {
        @media screen and (max-width: 768px) {
            border-bottom: 5px solid #404041 !important;
            padding: 20px;
        }
    }
    .top_icon_menu {
        display: none;
        @media screen and (max-width: 768px) {
            display: block;
            position: absolute;
            left: 50px;
            top: 38px;
            width: 38px;
            height: 38px;
        }
    }
    .igo_top {
        @media screen and (max-width: 768px) {
            zoom: 2;
            min-width: 0px;
            margin-left: 0px;
        }

        height: 120px;
        min-width: 820px;
        margin-left: 260px;
        background-color: #171621 !important;
        .lianjie_anniu {
            width: 120px;
            height: 40px;
            position: absolute;
            right: 50px;
            top: 38px;
            background: #F5B237;
            box-shadow: 1px 3px 0px 0px #AB5500;
            border-radius: 23px;
            font-size: 17px;
            font-family: Poppins;
            font-weight: bold;
            color: #FFFFFF;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
        }
        .top_binance {
            position: absolute;
            right: 190px;
            top: 38px;
            width: 40px;
            height: 40px;
        }
        .top_binance_eth {
            position: absolute;
            right: 190px;

            /*top: 30px;*/
            /*width: 30px;*/
            /*height: 55px;*/

            top: 40px;
            width: 42px;
            height: 42px;
        }
    }
    .top_mobile_menu {
        width: 100%;
        height: 100%;
        zoom: 2;
    }
</style>
