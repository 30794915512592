<template>
    <div class="igo_mobile_menu">
<!--        <div class="menu_logo">-->
<!--            <a href="https://cheersland.org/" target="_blank">-->
<!--                <img src="../../assets/images/pc/logo.png">-->
<!--            </a>-->
<!--        </div>-->
        <div class="left_menu">
            <div class="menu_navigation" @click="toColumn(0)" v-if="isBsc">
                <img src="../../assets/images/pc/shouye.png" />
                <p>Home</p>
            </div>

<!--            <div class="menu_navigation" @click="toColumn(1)">-->
<!--                <img src="../../assets/images/pc/igo.png" />-->
<!--                <p>IGO</p>-->
<!--            </div>-->

            <van-collapse v-model="activeNames">
                <van-collapse-item name="0">
                    <template #title>
                        <div><img class="menu_img igo_text" src="../../assets/images/pc/igo.png"> IGO</div>
                    </template>

                    <a @click="toColumn(2)">
                        <p class="menu_text">
                            <a>
                                <img class="menu_igo_img" src="../../assets/images/pc/bnb_token.png">BSC IGO
                            </a>
                        </p>
                    </a>

                    <p @click="toColumn(3)" class="menu_text">
                        <a>
                            <img class="menu_igo_img" src="../../assets/images/pc/eth_logo.png">ETH IGO
                        </a>
                    </p>

                    <p @click="toColumn(4)" class="menu_text">
                        <a>
                            <img class="menu_igo_img" src="../../assets/images/pc/avax_logo.png">AVAX IGO
                        </a>
                    </p>

                    <p @click="toColumn(5)" class="menu_text">
                        <a>
                            <img class="menu_igo_img" src="../../assets/images/pc/polygon_logo.png">POLYGON IGO
                        </a>
                    </p>
                </van-collapse-item>
            </van-collapse>

            <div class="menu_navigation" @click="toColumn(1)" v-if="isBsc">
                <img src="../../assets/images/pc/fatm.png" />
                <p>Farm</p>
            </div>

            <a href="https://www.cheersland.org/game.html" target="_blank" v-if="isBsc">
                <div class="menu_navigation">
                    <img src="../../assets/images/pc/play.png" />
                    <p>Play Now</p>
                </div>
            </a>

            <van-collapse v-model="activeNames" v-if="isBsc">
                <van-collapse-item name="1">
                    <template #title>
                        <div><img class="menu_img" src="../../assets/images/pc/earm.png"> Earn HP</div>
                    </template>

                    <a href="https://cheersland.org/staking.html" target="_blank">
                        <p class="menu_text">
                            <a>
                                Multi-asset Staking
                            </a>
                        </p>
                    </a>

                    <p class="menu_text" @click="toDialogInviteFriends()">
                        <a>
                            Invite Friends
                        </a>
                    </p>
                </van-collapse-item>
            </van-collapse>

            <a href="https://galaxy.eco/cheersland/" target="_blank">
                <div class="menu_navigation" v-if="isBsc">
                    <img src="../../assets/images/pc/nft.png" />
                    <p>NFT Market</p>
                </div>
            </a>

            <div class="menu_navigation" v-if="isBsc" @click="toColumn(6)">
                <img src="../../assets/images/pc/goven.png" />
                <p>Loyalty Governance</p>
            </div>
            <van-collapse v-model="activeNames" v-if="isBsc">
                <van-collapse-item name="2">
                    <template #title>
                        <div><img class="menu_img" src="../../assets/images/pc/more.png"> More</div>
                    </template>

                    <a href="https://solidity.finance/audits/CheersLand/" target="_blank">
                        <p class="menu_text">
                            <a>
                                Audit
                            </a>
                        </p>
                    </a>

                    <a href="https://github.com/cheersland" target="_blank">
                        <p class="menu_text">
                            <a>
                                Github
                            </a>
                        </p>
                    </a>

                    <a href="https://docs.cheersland.org/roadmap" target="_blank">
                        <p class="menu_text">
                            <a>
                                Roadmap
                            </a>
                        </p>
                    </a>

                    <p class="menu_text" @click="toDialogContact()">
                        <a>
                            Contact
                        </a>
                    </p>

                </van-collapse-item>
            </van-collapse>
        </div>
        <div class="left_balance" v-if="isBsc">
            <img src="../../assets/images/pc/money.png">
            <div class="text_num"> {{$store.state.systemInfo.cheersPrice !== 'TBA' ? '$' : ''}}{{ setDecimal($store.state.systemInfo.cheersPrice, 6, false, false) }}</div>
        </div>
        <div class="left_link" v-if="isBsc">
            <a class="tel" href="https://t.me/cheersland" target="_blank">
                <img class="linkIoc2" src="../../assets/images/pc/tel.png">
                <img class="linkIoc1" src="../../assets/images/pc/tel.png">
            </a>
            <a class="twitter" href="https://twitter.com/cheers_land" target="_blank">
                <img class="linkIoc2" src="../../assets/images/pc/tew.png">
                <img class="linkIoc1" src="../../assets/images/pc/tew.png">
            </a>
            <a class="med" href="https://medium.com/cheersland" target="_blank">
                <img class="linkIoc2" src="../../assets/images/pc/mod.png">
                <img class="linkIoc1" src="../../assets/images/pc/mod.png">
            </a>
        </div>
    </div>
</template>

<script>
    import transfer from "../../tools/transfer";
    import toolUtils from "../../tools/toolUtils";

    export default {
        name: "MobileMenu",
        data() {
            return {
                accounts: null,
                isBsc: true,
                activeNames: ['0'],
            };
        },
        watch: {
        },
        created() {
            if (localStorage.getItem('accounts')) {
                this.accounts = localStorage.getItem('accounts')
            }
        },
        mounted() {
            this.initUrlIndex()
        },
        beforeDestroy() {
        },
        methods: {
            initUrlIndex() {
                // if (sessionStorage.getItem('urlIndex')) {
                //     this.toColumn(parseInt(sessionStorage.getItem('urlIndex')))
                // } else {
                //     this.toColumn(0)
                // }
                if (sessionStorage.getItem('isBsc')) {
                    this.isBsc = eval(sessionStorage.getItem('isBsc'))
                } else {
                    this.isBsc = true
                }
            },
            setDecimal(numVal, numDecimalMax, roundUp, science) {
                return toolUtils.setDecimal(numVal, numDecimalMax, roundUp, science)
            },
            toColumn(urlIndex) {
                if (urlIndex === 0) {
                    this.isBsc = true;
                    this.$router.push("/home");
                } else if (urlIndex === 1) {
                    this.isBsc = true;
                    this.$router.push("/mining");
                } else if (urlIndex === 2) {
                    if (this.isBsc === false) {
                        this.hide()
                        this.$store.dispatch('logOut')
                        this.$message.warning('You have switched to the BSC page, please switch to the BSC network and reconnect your wallet!');
                    }
                    this.isBsc = true;
                    this.$router.push("/fundraising");
                } else if (urlIndex === 3) {
                    this.$router.push("/ethigo");
                } else if (urlIndex === 4) {
                    this.$router.push("/avaxigo");
                } else if (urlIndex === 5) {
                    this.$router.push("/maticigo");
                } else if (urlIndex === 6) {
                    this.isBsc = true;
                    this.$router.push("/ranking");
                }

                if (urlIndex === 3 || urlIndex === 4 || urlIndex === 5) {
                    if (sessionStorage.getItem('urlIndex') && parseInt(sessionStorage.getItem('urlIndex')) !== urlIndex) {
                        this.hide()
                        this.$store.dispatch('logOut')
                        var typeText = ''
                        if (urlIndex === 3) {
                            typeText = 'ETH'
                        } else if (urlIndex === 4) {
                            typeText = 'AVAX'
                        } else if (urlIndex === 5) {
                            typeText = 'POLYGON'
                        }
                        var text = 'You have switched to the ' + typeText + ' page, please switch to the ' + typeText + ' network and reconnect your wallet!'
                        this.$message.warning(text)
                    }
                    this.isBsc = false;
                }

                sessionStorage.setItem('urlIndex', urlIndex)
                sessionStorage.setItem('isBsc', this.isBsc)
            },
            toDialogContact() {
                transfer.$emit('openDialogContact')
            },
            toDialogInviteFriends() {
                if (localStorage.getItem('accounts')) {
                    this.accounts = localStorage.getItem('accounts')
                }
                if (this.accounts && this.accounts !== 'null') {
                    transfer.$emit('openDialogInviteFriends', this.accounts)
                } else {
                    this.$message('Please connect your wallet first!');
                }
            },
            hide() {
                this.$store.commit('setState', {
                    connectWalletShow: false
                })
            }
        }
    }
</script>

<style lang="less">
    .igo_mobile_menu {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50px;
        bottom: 0;
        background-color: #171621 !important;
        .menu_logo {
            height: 150px;
            text-align: center;
            img {
                width: 150px;
                margin-top: 20px;
            }
        }
        .left_menu::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        .left_menu {
            height: calc(100% - 185px);
            overflow-y: auto;
            .menu_navigation:hover {
                background-color: #404041;
            }
            .menu_navigation {
                height: 45px;
                display: flex;
                justify-content: flex-start;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                color: #FFFFFF;
                img {
                    margin-left: 15px;
                    width: 25px;
                }
                p {
                    font-size: 15px;
                    margin-left: 5px;
                }
            }
            .menu_img {
                width: 25px;
                margin-bottom: -9px;
            }
            .menu_text {
                height: 30px;
                width: 260px;
                margin-left: -15px;
                cursor: pointer;
                a {
                    color: #ffffff;
                    line-height: 30px;
                    margin-left: 60px;
                }
                .menu_igo_img {
                    width: 20px;
                    margin: -5px 5px;
                }
            }
            .menu_text:hover {
                background-color: #404041;
            }
            .van-cell:hover {
                background-color: #404041 !important;
            }
            .van-cell {
                background-color: #171621 !important;
                color: #FFFFFF;
                align-items: flex-end !important;
            }
            .van-collapse-item__content {
                background-color: #171621 !important;
                padding: 0 16px !important;
                color: #FFFFFF;
            }
            .van-cell::after {
                border-bottom: 1px solid #171621 !important;
            }
            .van-collapse-item--border::after {
                border-top: 1px solid #171621 !important;
            }
            .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
                border-width: 0;
            }
            .van-cell__right-icon {
                color: #f5b336;
                margin-bottom: -5px;
            }
        }
        .left_balance {
            display: flex;
            justify-content: center;
            margin-top: 30px;
            border-bottom: 2px solid #404041 !important;
            img {
                width: 28px;
                height: 28px;
                margin-bottom: 10px;
                cursor: pointer;
            }
            .text_num {
                color: #FFFFFF;
                font-weight: 600;
                line-height: 30px;
                margin-left: 10px;
                margin-bottom: 10px;
            }
        }
        .left_link {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            margin-top: 10px;

            img {
                width: 40px;
                cursor: pointer;
            }
            .linkIoc1 {
                display: none;
            }
            .linkIoc2 {
                display: block;
            }
            .tel:hover {
                .linkIoc1 {
                    display: block;
                }

                .linkIoc2 {
                    display: none;
                }
            }
            .twitter:hover {
                .linkIoc1 {
                    display: block;
                }

                .linkIoc2 {
                    display: none;
                }
            }
            .med:hover {
                .linkIoc1 {
                    display: block;
                }

                .linkIoc2 {
                    display: none;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .el-message {
            top: 120px !important;
            zoom: 3 !important;
        }
    }
</style>